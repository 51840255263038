import { ContactUsForm } from '@pbx/shared/ui/forms/contact-us-form';
import { Text } from '@pbx/shared/ui/common/text';
import { RichText } from '@pbx/shared/ui/common/rich-text';

import { SubmissionSuccess } from './submission-success';
import { TContactUsFormBlockProps } from './contact-us-form-block.types';

export const ContactUsFormBlock = ({
  title,
  description,
  isSuccess,
  onFormSubmit,
  isPending,
}: TContactUsFormBlockProps) => {
  return (
    <div className="container">
      <div className="flex gap-8 sm:flex-col md:flex-col lg:flex-row">
        <div className="sm:w-full md:w-full lg:w-1/2">
          <div className="sm:max-w-full md:max-w-full lg:max-w-[455px]">
            <Text
              variant="heading"
              as="h2"
              size="large"
              className="text-foreground-primary mb-6"
            >
              {title}
            </Text>
            <RichText
              className="text-foreground-secondary"
              text={description}
            />
          </div>
        </div>
        <div className="bg-background-primary sm:bg-background-tertiary md:bg-background-tertiary sm:w-full sm:p-6 md:w-full md:p-6 lg:w-3/5">
          {isSuccess ? (
            <SubmissionSuccess />
          ) : (
            <ContactUsForm onFormSubmit={onFormSubmit} isPending={isPending} />
          )}
        </div>
      </div>
    </div>
  );
};
