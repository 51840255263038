import { fetchApi, TUseMutationOptions } from '@pbx/shared/api-client/api-core';
import { useMutation } from '@pbx/shared/api-client/hooks-core';

import type { TMutationRequestBody } from './create-contact-us-request.types';

export async function createContactUsRequest(
  body: TMutationRequestBody
): Promise<unknown> {
  return await fetchApi(
    `${process.env.NEXT_PUBLIC_PB_BASE_API_URL}/communications-api/v1/contactus`,
    {
      method: 'POST',
      body,
      mode: 'cors',
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    }
  );
}

export function useCreateContactUsRequest(
  config: TUseMutationOptions<unknown, Error, TMutationRequestBody> = {}
) {
  return useMutation<unknown, TMutationRequestBody, Error>({
    mutationFn: (variables: TMutationRequestBody) =>
      createContactUsRequest(variables),
    ...config,
  });
}
