'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import ReCAPTCHA from 'react-google-recaptcha';
import { FocusEvent, useState } from 'react';

import { useAnalytics } from '@pbx/shared/analytics';
import { Form, FormField } from '@pbx/shared/ui/common/form/form';
import { Button } from '@pbx/shared/ui/common/button';
import {
  emailValidation,
  phoneNumberValidation,
  firstNameValidation,
  lastNameValidation,
  textareaValidation,
} from '@pbx/shared/utilities/validation/zod';
import { Textarea } from '@pbx/shared/ui/common/form/textarea';
import { Select } from '@pbx/shared/ui/common/form/select';

import { PersonalDetails } from './personal-details/personal-details';
import { EReason, TContactUsForm } from './contact-us-form.types';

const formSchema = z.object({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  subject: z.string().optional(),
  phoneNumber: phoneNumberValidation,
  emailAddress: emailValidation,
  reason: z.nativeEnum(EReason, {
    message: 'Please select an option!',
  }),
  comments: textareaValidation,
});

export const ContactUsForm = ({ onFormSubmit, isPending }: TContactUsForm) => {
  const [reCaptchaSubmitted, setReCaptchaSubmitted] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
  });

  const {
    trackGAFormEvent: { trackInputChange, trackSubmitError, trackSubmit },
  } = useAnalytics();

  const {
    formState: { isValid },
  } = form;

  function onBlur(e: FocusEvent<HTMLFormElement>) {
    trackInputChange(e.target);
  }

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (!isValid || !reCaptchaSubmitted) return;
    onFormSubmit(values, {
      onSuccess: () => {
        trackSubmit(values);
      },
      onError: (error) => trackSubmitError(error),
    });
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onBlur={onBlur}
        className="bg-background-tertiary lg:p-13 space-y-8"
      >
        <PersonalDetails />
        <FormField control={form.control} name="reason">
          <Select
            label="Reason for query"
            options={[
              {
                value: EReason.PROPERTY_VALUATION,
                label: 'Property valuation',
              },
              { value: EReason.PROPERTY_VIEWING, label: 'Property viewing' },
              { value: EReason.PROPERTY_LISTING, label: 'Property listing' },
              { value: EReason.PROPERTY_OFFER, label: 'Property offer' },
              { value: EReason.FEES_QUERY, label: 'Fees query' },
              { value: EReason.AGENT_QUERY, label: 'Agent query' },
              { value: EReason.SOLD_PROPERTY, label: 'Sold property' },
              { value: EReason.LETTINGS_PROCESS, label: 'Lettings process' },
              { value: EReason.ACCOUNT_PROBLEMS, label: 'Account problems' },
              {
                value: EReason.CANCELLATION,
                label: 'Cancellation (14 days cooling of period)',
              },
            ]}
            className="w-full"
            placeholder="Select an option"
          />
        </FormField>
        <FormField control={form.control} name="comments">
          <Textarea
            label="Please describe the situation you'd like us to look into"
            className="w-full"
          />
        </FormField>
        <ReCAPTCHA
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY as string}
          onChange={() => {
            setReCaptchaSubmitted(true);
          }}
          onExpired={() => {
            setReCaptchaSubmitted(false);
          }}
          onErrored={() => {
            setReCaptchaSubmitted(false);
          }}
        />
        <div className="flex justify-end">
          <Button
            disabled={!isValid || !reCaptchaSubmitted || isPending}
            type="submit"
          >
            Submit enquiry
          </Button>
        </div>
      </form>
    </Form>
  );
};
