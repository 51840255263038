import { cva } from 'class-variance-authority';

import { textVariants } from '@pbx/shared/ui/common/text';

export const variants = cva(
  `${textVariants({ variant: 'ui', size: 'medium-400' })} px-4 py-3 outline-none transition-border transition-shadow focus-visible:border-input-focus bg-background-primary border rounded-medium`,
  {
    variants: {
      variant: {
        default: 'border-ui',
        error: 'border-error',
        disabled:
          'border-border-disabled bg-background-disabled text-foreground-disabled cursor-not-allowed',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
