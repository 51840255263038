import { z } from 'zod';

import {
  FIRST_LAST_NAME_REGEX,
  ONLY_DIGITS_REGEX,
  POSTCODE_REGEX,
} from '@pbx/shared/utilities/validation/regex';

export const emptyStringLiteralValidation = z
  .union([z.literal(''), z.undefined()])
  .refine(() => false, { message: 'Field is required' });

export const emailValidation = z.string().email();

export const phoneNumberValidation = z
  .string()
  .min(11, 'Phone number must be at least 11 digits')
  .regex(ONLY_DIGITS_REGEX, 'Phone number must contain only digits.');

export const firstNameValidation = z
  .string()
  .min(2, {
    message: 'First name must be at least 2 characters',
  })
  .regex(FIRST_LAST_NAME_REGEX, {
    message: 'First name should only contain alphabetic characters and hyphen',
  });

export const lastNameValidation = z
  .string()
  .min(2, {
    message: 'Last name must be at least 2 characters',
  })
  .regex(FIRST_LAST_NAME_REGEX, {
    message: 'Last name should only contain alphabetic characters and hyphen',
  });

export const postcodeValidation = z.string().regex(POSTCODE_REGEX, {
  message: 'Please enter a valid postcode',
});

export const textareaValidation = z.string().min(1).max(1000, {
  message: 'You have exceeded the maximum number of characters',
});
