import type { TContactUsForm as formData } from '@pbx/shared/api-client/services/communications';

type TOnFormSubmitConfig = {
  onSuccess: (data: formData) => void;
  onError: (e: Error) => void;
};

export type TContactUsForm = {
  onFormSubmit: (values: formData, config?: TOnFormSubmitConfig) => void;
  isPending: boolean;
};

export enum EReason {
  PROPERTY_VALUATION = 'property-valuation',
  PROPERTY_VIEWING = 'property-viewing',
  PROPERTY_LISTING = 'property-listing',
  PROPERTY_OFFER = 'property-offer',
  FEES_QUERY = 'fees-query',
  AGENT_QUERY = 'agent-query',
  SOLD_PROPERTY = 'sold-property',
  LETTINGS_PROCESS = 'lettings-process',
  ACCOUNT_PROBLEMS = 'account-problems',
  CANCELLATION = 'cancellation',
}
