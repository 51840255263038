import {
  useQuery as useQueryTanstack,
  UseQueryOptions,
  useQueries,
} from '@tanstack/react-query';

export function useQuery<TData>(props: UseQueryOptions<TData>) {
  return useQueryTanstack(props);
}

/*
 * Re-exporting here to be able
 * to add wrapper when needed.
 */

export { useQueries };
