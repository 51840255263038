'use client';
import React from 'react';
import { usePathname } from 'next/navigation';

import { Breadcrumb } from '@pbx/shared/ui/common/breadcrumb';

import {
  getBreadcrumbItems,
  getSlugArray,
} from './breadcrumb-container.helpers';
import { TBreadcrumbContainerProps } from './breadcrumb-container.types';

export const BreadcrumbContainer = ({
  pages,
  isPrimary,
}: TBreadcrumbContainerProps) => {
  const pathname = usePathname();

  // Hide breadcrumb on home
  if (pathname === '/') return null;

  const slugArray = getSlugArray(pathname);
  const breadcrumbItems = getBreadcrumbItems({ slugArray, pages });

  return <Breadcrumb items={breadcrumbItems} isPrimary={isPrimary} />;
};
