export const GET_MORTGAGES_TRUST_PILOT_REVIEWS =
  'GET_MORTGAGES_TRUST_PILOT_REVIEWS';
export const GET_MORTGAGES_TRUST_PILOT_SCORES =
  'GET_MORTGAGES_TRUST_PILOT_SCORES';
export const GET_MARKETING_TRUST_PILOT_REVIEWS =
  'GET_MARKETING_TRUST_PILOT_REVIEWS';
export const GET_MARKETING_TRUST_PILOT_SCORES =
  'GET_MARKETING_TRUST_PILOT_SCORES';
export const GET_MARKETING_TRUST_PILOT_REVIEWS_BY_BUSINESS_UNIT =
  'GET_MARKETING_TRUST_PILOT_REVIEWS_BY_BUSINESS_UNIT';
export const GET_MARKETING_TRUST_PILOT_REVIEWS_BY_AGENT =
  'GET_MARKETING_TRUST_PILOT_REVIEWS_BY_AGENT';
export const GET_MARKETING_TRUST_PILOT_REVIEWS_BY_AGENTS =
  'GET_MARKETING_TRUST_PILOT_REVIEWS_BY_AGENTS';
