import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { Icon } from '@pbx/shared/ui/common/icon';
import { Text } from '@pbx/shared/ui/common/text';
import { TLink } from '@pbx/shared/contentful/helpers';

import { TMegaMenu } from './mega-menu.types';
import { NavigationLink } from '../navigation-link/navigation-link';
import { MEGA_MENU_COLUMNS } from '../header.constants';

export function MegaMenu({ mainMenu, reviewLinks }: TMegaMenu) {
  const ColumnSeparatorLine = () => {
    return (
      <div className="bg-border-visual absolute inset-y-0 left-[-12%] w-[1px]" />
    );
  };

  const renderSubMenuLinkColumns = (linkColumn: TLink[]) => {
    return (
      <div className="flex flex-col">
        {linkColumn.map(({ label, link }, index) => (
          <NavigationMenu.Link
            key={`${label}-${index}`}
            className="max-w-[15rem] pb-4"
            href={link.href}
          >
            <Text
              variant="ui"
              size="medium-500"
              className="text-foreground-highlight hover:underline"
            >
              {label}
            </Text>
          </NavigationMenu.Link>
        ))}
      </div>
    );
  };

  const renderReviewLinks = () => {
    return reviewLinks.map(({ label, link, icon }, index) => (
      <a key={`${label}-${index}`} href={link.href}>
        {icon ? (
          <img src={icon.src} alt={icon.alt} className="max-h-6 max-w-full" />
        ) : (
          <span>{label}</span>
        )}
      </a>
    ));
  };

  const splitMenuColumns = (links: TLink[]) => {
    if (!links?.length) return [];

    const totalLinks = links.length + 1; // +1 to Include trusted links
    const numLinksPerPart = Math.floor(totalLinks / MEGA_MENU_COLUMNS);
    let remainingLinks = totalLinks % MEGA_MENU_COLUMNS;
    const linkParts: TLink[][] = [];
    let startIndex = 0;

    Array(MEGA_MENU_COLUMNS)
      .fill(0)
      .forEach(() => {
        const partSize = numLinksPerPart + (remainingLinks > 0 ? 1 : 0);
        const partLinks = links.slice(startIndex, startIndex + partSize);
        linkParts.push(partLinks);
        startIndex += partSize;
        remainingLinks -= 1;
      });
    return linkParts;
  };

  const renderMegaMenu = () => {
    return mainMenu.map(({ title, links }, index) => {
      const linkColumns = splitMenuColumns(links);
      const hasLinks = links.length > 0;

      return (
        <NavigationMenu.Item key={`${title?.label}-${index}`}>
          {hasLinks ? (
            <NavigationMenu.Trigger className="group flex select-none items-center justify-between leading-none outline-none">
              <NavigationLink
                variant="main-menu"
                link={title?.link.href}
                iconPosition="right"
                icon={
                  <Icon
                    name="disclosure-arrow-down"
                    width={12}
                    height={12}
                    strokeWidth={3}
                  />
                }
              >
                {title?.label}
              </NavigationLink>
            </NavigationMenu.Trigger>
          ) : (
            <NavigationLink variant="main-menu" link={title?.link.href}>
              {title?.label}
            </NavigationLink>
          )}

          <NavigationMenu.Content className="bg-background-secondary data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight fixed left-8 right-8 top-full z-10 m-auto max-w-[1366px] px-[10%] py-[2rem]">
            <ul className="m-0 flex list-none justify-between gap-x-10 gap-y-5">
              {linkColumns.map((linkColumn, columnIndex) => (
                <div key={`part-${columnIndex}`} className="relative">
                  {renderSubMenuLinkColumns(linkColumn)}

                  {columnIndex === 2 && (
                    <li
                      key={`trusted-links-${columnIndex}`}
                      className="flex flex-row items-center gap-4 pt-2"
                    >
                      {renderReviewLinks()}
                    </li>
                  )}
                  {columnIndex > 0 && <ColumnSeparatorLine />}
                </div>
              ))}
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      );
    });
  };

  return (
    <NavigationMenu.Root className="flex justify-center">
      <NavigationMenu.List className="center ml-3 flex list-none items-center gap-1">
        {renderMegaMenu()}
        <NavigationMenu.Indicator className="text-background-secondary flex items-end justify-center">
          <div className="border-small border-x-button-tertiary-background border-background-secondary z-10 h-0 w-0 border-b-[0.8rem] border-l-[0.8rem] border-r-[0.8rem] border-r-transparent" />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>
      <div className="bg-background-primary fixed left-0 top-full flex">
        <NavigationMenu.Viewport className="data-[state=open]:animate-fadeIn data-[state=closed]:animate-fadeOut bg-background-secondary duration-400 relative min-w-full transition-opacity" />
      </div>
    </NavigationMenu.Root>
  );
}
