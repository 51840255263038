import { useQuery } from '@pbx/shared/api-client/hooks-core';
import { fetchApi } from '@pbx/shared/api-client/api-core';

import {
  TGetTrustPilotReviewsParams,
  TTrustPilotReviews,
  TUseGetTrustPilotReviews,
} from '../reviews.types';
import { buildGetReviewsQuery } from '../helpers/query-builders';
import { GET_MARKETING_TRUST_PILOT_REVIEWS } from '../reviews.constants';

export async function getMarketingTrustPilotReviews({
  stars = [5, 4],
  pageSize = 3,
}: TGetTrustPilotReviewsParams) {
  const queryParams = buildGetReviewsQuery(stars, pageSize);

  return await fetchApi<TTrustPilotReviews>(
    `${process.env.NEXT_PUBLIC_MARKETING_REVIEWS_API_URL}/Reviews/provider/trustpilot?${queryParams}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetMarketingTrustPilotReviews({
  stars,
  pageSize,
  config,
}: TUseGetTrustPilotReviews) {
  return useQuery({
    queryKey: [GET_MARKETING_TRUST_PILOT_REVIEWS, stars, pageSize],
    queryFn: () => getMarketingTrustPilotReviews({ stars, pageSize }),
    ...config,
  });
}
