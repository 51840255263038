import { getSpaceConfig } from '@pbx/shared/contentful/services';
import { transformComponentsData } from '@pbx/shared/contentful/helpers';
import { TSpaceConfigEntry } from '@pbx/shared/contentful/types';

import {
  TComponentWrapperProps,
  TTransformedComponents,
  TGetContentfulComponents,
} from '../contentful-content-provider/contentful-content-provider.types';
import { ContentfulContentProvider } from '../contentful-content-provider/contentful-content-provider';

async function getContentfulComponents({
  isPreview,
}: TGetContentfulComponents): Promise<TTransformedComponents | undefined> {
  try {
    const config = await getSpaceConfig<TSpaceConfigEntry>(isPreview);

    return transformComponentsData<TTransformedComponents>(config);
  } catch (e) {
    console.log('Failed to fetch config:', e);
  }
}

export async function ContentfulComponentsProvider({
  children,
  componentMap,
  isPreview = false,
}: TComponentWrapperProps) {
  const components = await getContentfulComponents({ isPreview });

  return (
    <ContentfulContentProvider
      components={components}
      componentsMap={componentMap}
    >
      {children}
    </ContentfulContentProvider>
  );
}
