import type { TComplaintsForm as formData } from '@pbx/shared/api-client/services/communications';

type TOnFormSubmitConfig = {
  onSuccess: (data: formData) => void;
  onError: (e: Error) => void;
};

export type TComplaintsForm = {
  onFormSubmit: (values: formData, config?: TOnFormSubmitConfig) => void;
  isPending: boolean;
};

export enum EReason {
  PROPERTY_ADVERT = 'property-advert-complaint',
  PURPLEBRICKS_EMPLOYEE = 'purplebricks-employee-complaint',
  AGENTS_AVAILABILITY = 'agent-availability-complaint',
  CONVEYANCING_SERVICE = 'conveyancing-service-complaint',
  PAYMENT_TERMS = 'payment-terms-complaint',
  PROPERTY_OFFER = 'property-offer-complaint',
  PROPERTY_VIEWING = 'property-viewing-complaint',
  MONEY_BACK_GUARANTEE = 'money-back-guarantee-complaint',
}

export enum EComplaintsFormType {
  SALES = 'sales',
  LETTINGS = 'lettings',
}

export type ExtendedAddress = {
  postcode: string;
  post_town: string;
  thoroughfare: string;
  building_number: string;
};
