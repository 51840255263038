import Script from 'next/script';
import { GoogleTagManager as NextGoogleTagManager } from '@next/third-parties/google';

export function GoogleTagManager({ tagManagerId }: { tagManagerId: string }) {
  if (!tagManagerId) return null;
  return (
    <>
      <Script
        data-cookieconsent="ignore"
        data-testid="google-consent-mode"
        id="google-consent-mode"
        dangerouslySetInnerHTML={{
          __html: `
            <!-- Google Consent Mode -->
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('consent', 'default', {
                ad_personalization: 'denied',
                ad_storage: 'denied',
                ad_user_data: 'denied',
                analytics_storage: 'denied',
                functionality_storage: 'denied',
                personalization_storage: 'denied',
                security_storage: 'granted',
                wait_for_update: 500,
            });
            gtag('set', 'ads_data_redaction', true);
            gtag('set', 'url_passthrough', false);
            <!-- End Google Consent Mode -->
            `,
        }}
      />
      <NextGoogleTagManager gtmId={tagManagerId} />
    </>
  );
}
