'use client'; // Error boundaries must be Client Components

import { useEffect } from 'react';

import { Button } from '@pbx/shared/ui/common/button';
import { DefaultLayout } from '@pbx/apps/marketing-website/layouts/default';
import { Text } from '@pbx/shared/ui/common/text';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <DefaultLayout>
      <section className="sm:pb-16 sm:pt-16 md:pb-20 md:pt-20 lg:pb-28 lg:pt-28">
        <div className="text-col container mx-auto flex max-w-lg flex-col items-center text-center">
          <Text variant="display" size="small" as="p" className="mb-4">
            Something went wrong!
          </Text>
          <Text className="mb-12" as="p">
            Sorry, we are unable to load this page at the moment. Please try
            again or contact support.
          </Text>
          <Button
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            Try again
          </Button>
        </div>
      </section>
    </DefaultLayout>
  );
}
