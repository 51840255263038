'use client';

import { useFormContext } from 'react-hook-form';

import { FormField } from '@pbx/shared/ui/common/form/form';
import { Input } from '@pbx/shared/ui/common/form/input';

export function PersonalDetails() {
  const { control } = useFormContext();

  return (
    <div className="space-y-4">
      <div className="flex items-start gap-4 sm:flex-col md:flex-row lg:flex-row">
        <FormField control={control} name="firstName" className="w-full">
          <Input label="First Name" className="w-full" />
        </FormField>
        <FormField control={control} name="lastName" className="w-full">
          <Input label="Last Name" className="w-full" />
        </FormField>
      </div>
      <FormField control={control} name="subject" className="w-full">
        <Input label="Subject (optional)" className="w-full" />
      </FormField>
      <div className="flex items-start gap-4 sm:flex-col md:flex-row lg:flex-row">
        <FormField control={control} name="phoneNumber" className="w-full">
          <Input label="Phone Number" className="w-full" />
        </FormField>
        <FormField control={control} name="emailAddress" className="w-full">
          <Input label="Email" className="w-full" />
        </FormField>
      </div>
    </div>
  );
}
