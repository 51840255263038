export {
  getLocationStats,
  useGetLocationStats,
} from './lib/get-location-stats/get-location-stats';

export {
  getLocations,
  useGetLocations,
} from './lib/get-locations/get-locations';

export {
  getLandRegistrySoldHousePricesByLocation,
  useGetLandRegistrySoldHousePricesByArea,
} from './lib/get-landregistry-sold-house-prices-by-location/get-landregistry-sold-house-prices-by-location';

export { GET_LOCATION_STATS } from './lib/location.constants';
export type {
  TLocationStatistics,
  TLocation,
  TLandRegistryHousePrices,
} from './lib/location.types';
