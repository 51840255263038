'use client';

import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Text } from '@pbx/shared/ui/common/text';
import { Input } from '@pbx/shared/ui/common/form/input';
import { Form, FormField } from '@pbx/shared/ui/common/form/form';
import { Select } from '@pbx/shared/ui/common/form/select';
import { Button } from '@pbx/shared/ui/common/button';
import { cn } from '@pbx/shared/utilities/combine-class-names';
import { convertLocalStringValueToNumber } from '@pbx/shared/utilities/convert-local-string-value-to-number';
import { formatAsLocaleString } from '@pbx/shared/utilities/format-as-locale-string';
import { buildQueryString } from '@pbx/shared/utilities/build-query-string';

import { getResults } from './helpers/helpers';
import { variants } from './stamp-duty-calculator.variants';
import { TStampDutyCalculatorProps } from './stamp-duty-calculator.types';
import { validationSchema } from './stamp-duty-calculator.validation';

export function StampDutyCalculator({
  hasBackground = true,
  logo,
  title,
  text,
  ctaText,
  ctaUrl,
}: TStampDutyCalculatorProps) {
  const form = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      propertyValue: 0,
      location: 'england',
    },
  });

  const parseAmount = useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      const oldValue = form.getValues('propertyValue');
      const newValue =
        convertLocalStringValueToNumber((e.target as HTMLInputElement).value) ??
        0;

      if (newValue > 9999999) return oldValue;
      return newValue;
    },
    [form]
  );
  const formatAmount = useCallback((amount?: number) => {
    return amount ? formatAsLocaleString(String(amount)) : undefined;
  }, []);

  const formPropertyValue = form.watch('propertyValue') || 0;
  const formLocation = form.watch('location');

  const { firstTimeBuyer, movingHome, additionalHome } = getResults({
    propertyValue: formPropertyValue,
    selectedLocation: formLocation,
  });

  return (
    <Form {...form}>
      <form
        className={variants({ hasBackground })}
        data-testid="stamp-duty-calculator-container"
        onSubmit={form.handleSubmit((values) => {
          const url = ctaUrl + buildQueryString({ ...values });
          window.location.href = url;
        })}
      >
        {Boolean(logo?.src) && (
          <img
            src={logo?.src}
            alt={logo?.alt}
            className="max-w-[161px] sm:mb-9 md:mb-4 lg:mb-8"
            data-testid="stamp-duty-calculator-logo"
          />
        )}
        <Text
          variant="heading"
          size="large"
          as="h2"
          className="text-foreground-action-alt sm:mb-3 md:mb-3 lg:mb-3"
          data-testid="stamp-duty-calculator-title"
        >
          {title || 'Stamp Duty Calculator'}
        </Text>
        <Text
          as="p"
          variant="paragraph"
          size="large"
          className="text-foreground-secondary sm:mb-6 md:mb-6 lg:mb-8"
          data-testid="stamp-duty-calculator-description"
        >
          {text ||
            'Our stamp duty calculator will help you work out how much you might need to pay when buying a new property.'}
        </Text>
        <div className="grid gap-4 sm:mb-9 sm:grid-cols-1 md:mb-6 md:grid-cols-2 lg:mb-6 lg:grid-cols-2">
          <div>
            <Text
              as="h4"
              variant="heading"
              size="smallest"
              className="text-foreground-action mb-2"
              data-testid="stamp-duty-calculator-location"
            >
              Location of property
            </Text>
            <FormField control={form.control} name="location">
              <Select
                className="w-full"
                options={[
                  { label: 'England and Northern Ireland', value: 'england' },
                  { label: 'Scotland', value: 'scotland' },
                  { label: 'Wales', value: 'wales' },
                ]}
                placeholder="Select location"
              />
            </FormField>
          </div>
          <div>
            <Text
              as="h4"
              variant="heading"
              size="smallest"
              className="text-foreground-action mb-2"
              data-testid="stamp-duty-calculator-property-value"
            >
              Property value
            </Text>
            <FormField
              control={form.control}
              name="propertyValue"
              parse={parseAmount}
              format={formatAmount}
            >
              <Input
                type="text"
                className="border-border-contrast w-full focus:outline-none"
                icon="£"
                data-testid="mortgage-calculator-annual-income-input"
              />
            </FormField>
          </div>
        </div>
        <div
          className={cn(
            variants({
              hasCta: Boolean(ctaUrl),
            }),
            'grid sm:grid-cols-1 sm:gap-7 md:grid-cols-3 lg:grid-cols-3'
          )}
        >
          <div className="grid grid-cols-1">
            <Text
              variant="heading"
              size="smaller"
              className="text-foreground-action mb-3"
            >
              First time buyer
            </Text>
            <Text variant="heading" size="medium">
              £{formatAsLocaleString(Math.floor(firstTimeBuyer).toString())}
            </Text>
          </div>
          <div className="grid grid-cols-1">
            <Text
              variant="heading"
              size="smaller"
              className="text-foreground-action mb-3"
            >
              Moving Home
            </Text>
            <Text variant="heading" size="medium">
              £{formatAsLocaleString(Math.floor(movingHome).toString())}
            </Text>
          </div>
          <div className="grid grid-cols-1">
            <Text
              variant="heading"
              size="smaller"
              className="text-foreground-action mb-3"
            >
              Additional Home
            </Text>
            <Text variant="heading" size="medium">
              £{formatAsLocaleString(Math.floor(additionalHome).toString())}
            </Text>
          </div>
        </div>

        {Boolean(ctaUrl) && (
          <Button
            type="submit"
            variant="primary"
            className="bg-brand-button-primary-foreground w-full"
          >
            {ctaText || 'Speak to a mortgage advisor'}
          </Button>
        )}
      </form>
    </Form>
  );
}
