export function formatLocationPostTown(town: string): string {
  if (town.indexOf('-') > 0) {
    const formattedPostTown = UNIQUE_POST_TOWN[town];
    if (formattedPostTown) {
      return formattedPostTown;
    }
    return town.toUpperCase().replace(/-/g, ' ');
  }
  return town.toUpperCase();
}

export const UNIQUE_POST_TOWN: { [key: string]: string } = {
  'bishops-stortford': "BISHOP'S STORTFORD",
  boness: "BO'NESS",
  'kings-lynn': "KING'S LYNN",
  'rowlands-castle': "ROWLAND'S CASTLE",
  'bury-st-edmunds': 'BURY ST. EDMUNDS',
  'chalfont-st-giles': 'CHALFONT ST. GILES',
  'hinton-st-george': 'HINTON ST. GEORGE',
  'lytham-st-annes': 'LYTHAM ST. ANNES',
  'ottery-st-mary': 'OTTERY ST. MARY',
  'st-agnes': 'ST. AGNES',
  'st-albans': 'ST. ALBANS',
  'st-andrews': 'ST. ANDREWS',
  'st-asaph': 'ST. ASAPH',
  'st-austell': 'ST. AUSTELL',
  'st-bees': 'ST. BEES',
  'st-columb': 'ST. COLUMB',
  'st-helens': 'ST. HELENS',
  'st-ives': 'ST. IVES',
  'st-leonards-on-sea': 'ST. LEONARDS-ON-SEA',
  'st-neots': 'ST. NEOTS',
  'appleby-in-westmorland': 'APPLEBY-IN-WESTMORLAND',
  'ashby-de-la-zouch': 'ASHBY-DE-LA-ZOUCH',
  'ashton-under-lyne': 'ASHTON-UNDER-LYNE',
  'askam-in-furness': 'ASKAM-IN-FURNESS',
  'barrow-in-furness': 'BARROW-IN-FURNESS',
  'barrow-upon-humber': 'BARROW-UPON-HUMBER',
  'barton-upon-humber': 'BARTON-UPON-HUMBER',
  'berwick-upon-tweed': 'BERWICK-UPON-TWEED',
  'betws-y-coed': 'BETWS-Y-COED',
  'bexhill-on-sea': 'BEXHILL-ON-SEA',
  'blaydon-on-tyne': 'BLAYDON-ON-TYNE',
  'bradford-on-avon': 'BRADFORD-ON-AVON',
  'broughton-in-furness': 'BROUGHTON-IN-FURNESS',
  'burnham-on-crouch': 'BURNHAM-ON-CROUCH',
  'burnham-on-sea': 'BURNHAM-ON-SEA',
  'burton-on-trent': 'BURTON-ON-TRENT',
  'clacton-on-sea': 'CLACTON-ON-SEA',
  'cornhill-on-tweed': 'CORNHILL-ON-TWEED',
  'dalton-in-furness': 'DALTON-IN-FURNESS',
  'frinton-on-sea': 'FRINTON-ON-SEA',
  'grange-over-sands': 'GRANGE-OVER-SANDS',
  'grantown-on-spey': 'GRANTOWN-ON-SPEY',
  'henley-in-arden': 'HENLEY-IN-ARDEN',
  'henley-on-thames': 'HENLEY-ON-THAMES',
  'kirkby-in-furness': 'KIRKBY-IN-FURNESS',
  'lee-on-the-solent': 'LEE-ON-THE-SOLENT',
  'leigh-on-sea': 'LEIGH-ON-SEA',
  'llannerch-y-medd': 'LLANNERCH-Y-MEDD',
  'moreton-in-marsh': 'MORETON-IN-MARSH',
  'newbiggin-by-the-sea': 'NEWBIGGIN-BY-THE-SEA',
  'newport-on-tay': 'NEWPORT-ON-TAY',
  'newton-le-willows': 'NEWTON-LE-WILLOWS',
  'poulton-le-fylde': 'POULTON-LE-FYLDE',
  'ross-on-wye': 'ROSS-ON-WYE',
  'saltburn-by-the-sea': 'SALTBURN-BY-THE-SEA',
  'shipston-on-stour': 'SHIPSTON-ON-STOUR',
  'shoreham-by-sea': 'SHOREHAM-BY-SEA',
  'southend-on-sea': 'SOUTHEND-ON-SEA',
  'stockton-on-tees': 'STOCKTON-ON-TEES',
  'stoke-on-trent': 'STOKE-ON-TRENT',
  'stoke-sub-hamdon': 'STOKE-SUB-HAMDON',
  'stourport-on-severn': 'STOURPORT-ON-SEVERN',
  'stratford-upon-avon': 'STRATFORD-UPON-AVON',
  'sunbury-on-thames': 'SUNBURY-ON-THAMES',
  'sutton-in-ashfield': 'SUTTON-IN-ASHFIELD',
  'thornton-cleveleys': 'THORNTON-CLEVELEYS',
  'tyn-y-gongl': 'TYN-Y-GONGL',
  'walton-on-thames': 'WALTON-ON-THAMES',
  'wells-next-the-sea': 'WELLS-NEXT-THE-SEA',
  'westbury-on-severn': 'WESTBURY-ON-SEVERN',
  'westcliff-on-sea': 'WESTCLIFF-ON-SEA',
  'westgate-on-sea': 'WESTGATE-ON-SEA',
  'weston-super-mare': 'WESTON-SUPER-MARE',
  'wotton-under-edge': 'WOTTON-UNDER-EDGE',
};
