import { cache } from 'react';
import * as process from 'process';
import { createClient } from 'contentful';

const PB_CONTENTFUL_SPACE_ID = process.env.PB_CONTENTFUL_SPACE_ID || '';
const PB_CONTENTFUL_ACCESS_TOKEN = process.env.PB_CONTENTFUL_ACCESS_TOKEN || '';
const PB_CONTENTFUL_PREVIEW_ACCESS_TOKEN =
  process.env.PB_CONTENTFUL_PREVIEW_ACCESS_TOKEN || '';
const PB_CONTENTFUL_SHARED_SPACE_ID =
  process.env.PB_CONTENTFUL_SHARED_SPACE_ID || '';
const PB_CONTENTFUL_SHARED_ACCESS_TOKEN =
  process.env.PB_CONTENTFUL_SHARED_ACCESS_TOKEN || '';
const PB_CONTENTFUL_SHARED_CONFIG_ID =
  process.env.PB_CONTENTFUL_SHARED_CONFIG_ID || '';
const PB_CONTENTFUL_SHARED_PREVIEW_ACCESS_TOKEN =
  process.env.PB_CONTENTFUL_SHARED_PREVIEW_ACCESS_TOKEN || '';

// this might change in the future
export const PAGE_CONTENT_TYPE = 'page';
export const REDIRECT_CONTENT_TYPE = 'redirect';

const getClient = (spaceId?: string, accessToken?: string) =>
  createClient({
    space: spaceId || PB_CONTENTFUL_SPACE_ID,
    accessToken: accessToken || PB_CONTENTFUL_ACCESS_TOKEN,
  });

const getPreviewClient = (spaceId?: string, accessToken?: string) =>
  createClient({
    space: spaceId || PB_CONTENTFUL_SPACE_ID,
    accessToken: accessToken || PB_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    host: 'preview.contentful.com',
  });

export const getPageBySlug = cache(
  async <T>(slug: string, isPreview = false): Promise<T> => {
    const client = isPreview ? getPreviewClient() : getClient();

    const entries = await client.getEntries({
      content_type: PAGE_CONTENT_TYPE,
      'fields.slug': slug,
      include: 10,
      limit: 1000,
    });

    return entries?.items[0] as T;
  }
);

export const getPagesByTags = cache(
  async <T>(tags: string[], limit?: number): Promise<T> => {
    const client = getClient();
    const tagsToMatch = tags.join();

    const entries = await client.getEntries({
      content_type: PAGE_CONTENT_TYPE,
      'fields.tags.sys.id[in]': tagsToMatch,
      include: 10,
      limit,
    });

    return entries?.items as T;
  }
);

export const getRedirects = cache(async <T>(): Promise<T> => {
  const client = getClient();

  const entries = await client.getEntries({
    content_type: REDIRECT_CONTENT_TYPE,
    include: 1,
    limit: 1000,
  });

  return entries?.items as T;
});

export const getPages = cache(async <T>(): Promise<T> => {
  const client = getClient();

  const entries = await client.getEntries({
    content_type: PAGE_CONTENT_TYPE,
    include: 1,
    limit: 1000,
  });

  return entries?.items as T;
});

export const getSpaceConfig = cache(
  async <T>(isPreview: boolean): Promise<T> => {
    const spaceId = PB_CONTENTFUL_SHARED_SPACE_ID;
    const accessToken = PB_CONTENTFUL_SHARED_ACCESS_TOKEN;
    const previewAccessToken = PB_CONTENTFUL_SHARED_PREVIEW_ACCESS_TOKEN;
    const entryId = PB_CONTENTFUL_SHARED_CONFIG_ID;

    const client = isPreview
      ? getPreviewClient(spaceId, previewAccessToken)
      : getClient(spaceId, accessToken);

    const entries = await client.getEntries({
      'sys.id': entryId,
      include: 10,
      limit: 1000,
    });

    return entries?.items[0] as T;
  }
);
