import { Text } from '@pbx/shared/ui/common/text';
import { Button } from '@pbx/shared/ui/common/button';
import { Image } from '@pbx/shared/ui/common/image';
import { Link } from '@pbx/shared/ui/common/link';
import { useAnalytics } from '@pbx/shared/analytics';
import { formatPostTown } from '@pbx/shared/utilities/format-post-town';
import { transformPostTownToSlug } from '@pbx/shared/utilities/transform-post-town-to-slug';
import { replaceUrlDomain } from '@pbx/shared/utilities/replace-url-domain';

import { TLocalExpertsProperties } from './local-experts.types';

export function LocalExperts({
  localExperts,
  postTown,
  allTimeSales,
}: TLocalExpertsProperties) {
  const analytics = useAnalytics();
  const basePath = process.env.NEXT_PUBLIC_PB_BASE_PATH || '';
  const imgixAgentUrl = process.env.NEXT_PUBLIC_IMGIX_AGENT_URL || '';

  function handleMeetExpertsButtonClick() {
    if (postTown) {
      analytics?.trackGAEvent('Button', {
        event: 'Button',
        children: 'find out more',
        href: `${basePath}/estate-agents/${transformPostTownToSlug(postTown)}`,
      });
    }
  }
  return (
    <div className="bg-background-secondary rounded-medium border-visual flex h-fit flex-1 flex-col gap-4 p-6">
      <div className="lg:flew-row flex gap-4 sm:flex-col md:flex-row md:place-content-between lg:place-content-between">
        <div className="basis-1/3">
          {allTimeSales < 2 ? (
            <Text variant="heading" size="smaller">
              We sell properties in {formatPostTown(postTown)}
            </Text>
          ) : (
            <div className="flex flex-col">
              <Text variant="heading" size="smaller">
                We’ve sold
              </Text>
              <Text
                variant="display"
                size="small"
                className="text-foreground-highlight"
              >
                {allTimeSales}
              </Text>
              <Text variant="heading" size="smaller">
                properties in {formatPostTown(postTown)}
              </Text>
            </div>
          )}
        </div>
        <div className="flex flex-row gap-4">
          {localExperts.map((expert, index) => (
            <Image
              height={64}
              sizes="120px"
              src={replaceUrlDomain(expert.photoUrl || '', imgixAgentUrl)}
              width={64}
              alt={`${expert.forename} ${expert.surname}`}
              className="border-visual aspect-square max-h-[64px] rounded-[50%]"
              key={index}
              responsive={false}
              style={{ objectFit: 'cover', objectPosition: 'top' }}
            />
          ))}
        </div>
      </div>
      <Button
        className="w-full"
        variant="ghost"
        onClick={handleMeetExpertsButtonClick}
      >
        <Link
          href={`${basePath}/estate-agents/${transformPostTownToSlug(
            postTown
          )}`}
        >
          Meet your local experts
        </Link>
      </Button>
    </div>
  );
}
