import Script from 'next/script';
import ReactDOM from 'react-dom';

export function CookieManager({
  cookieManagerId,
}: {
  cookieManagerId: string;
}) {
  const cookiebotUrl = process.env.NEXT_PUBLIC_COOKIEBOT_URL ?? '';
  if (!cookieManagerId || !cookiebotUrl) return null;
  ReactDOM.preconnect(cookiebotUrl);
  return (
    <Script
      id="Cookiebot"
      data-testid="cookie-manager"
      src={`${cookiebotUrl}/uc.js`}
      data-cbid={cookieManagerId}
      type="text/javascript"
      async
    ></Script>
  );
}
