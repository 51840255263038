'use client';

import { ComplaintsFormBlock } from '@pbx/shared/ui/blocks/complaints-form-block';
import { useCreateContactUsRequest } from '@pbx/shared/api-client/services/communications';

import { TComplaintsFormBlockContainerProps } from './complaints-form-block-container.types';

export const ComplaintsFormBlockContainer = ({
  title,
  description,
}: TComplaintsFormBlockContainerProps) => {
  const { mutate, isSuccess, isPending } = useCreateContactUsRequest();

  return (
    <ComplaintsFormBlock
      onFormSubmit={mutate}
      isSuccess={isSuccess}
      isPending={isPending}
      title={title}
      description={description}
    />
  );
};
