import { Text, textVariants } from '@pbx/shared/ui/common/text';
import { EShapeVariants, ShapeHouse } from '@pbx/shared/ui/shapes/shape-house';
import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Image } from '@pbx/shared/ui/common/image';

export function SubmissionSuccess() {
  const imgixUrl = process.env.NEXT_PUBLIC_PB_IMGIX_URL;

  return (
    <div className="bg-background-tertiary lg:p-13 flex h-full flex-col items-center sm:p-6 md:p-6">
      <div className="flex max-w-[560px] flex-col gap-8">
        <ShapeHouse shape={EShapeVariants.TWO}>
          <Image
            src={`${imgixUrl}/web-images/mortgages/images/mortgages_lead_capture.png`}
            alt="submission success image"
            width={560}
            height={560 * (9 / 16)}
          />
        </ShapeHouse>
        <Text as="h2" variant="heading" size="medium">
          Thank you for getting in touch
        </Text>
        <ul
          className={cn(
            textVariants({ variant: 'paragraph', size: 'medium' }),
            'ml-4'
          )}
        >
          <li className="list-disc">We'll be in touch as soon as possible.</li>
          <li className="list-disc">
            If you need to speak with someone urgently, please call us.
          </li>
          <li className="list-disc">
            Have questions in the meantime? Browse our FAQs.
          </li>
        </ul>
      </div>
    </div>
  );
}
