import {
  TAssetDetails,
  TAssetEntry,
  TEntry,
  TNavigationEntry,
  TRichTextDocument,
  TTagEntry,
} from '@pbx/shared/contentful/types';
import {
  TBlockRichTextEntry,
  TButtonEntry,
  TFAQItemEntry,
  TFeatureListEntry,
  TIconEntry,
  TListItemEntry,
  TPageEntry,
  TQuickActionCardEntry,
  TRelatedContentCardEntry,
  TSequenceItemEntry,
  TServiceCardEntry,
  TTabbedContentItemEntry,
  TLinkEntry,
} from '@pbx/apps/marketing-website/types';
import { addLeadingSlash } from '@pbx/shared/utilities/add-leading-slash';

import { getContentType } from '../../get-content-type/get-content-type';
import {
  TImg,
  TLink,
  TMenu,
  TNavigation,
  TButton,
  TTag,
  TFAQItem,
  TIcon,
  TSequenceItem,
  TTabContentItem,
} from './flatten-content-models.types';
import {
  transformContentBlocks,
  transformPageData,
} from '../../transform-page-data/transform-page-data';
import { TPageData } from '../../transform-page-data/transform-page-data.types';

/*
 * Use this function to flatten an asset entry
 * that's nested in a contentful entry
 */
export function flattenAsset(data: TAssetEntry): TImg | null {
  if (!data?.fields?.file) {
    return null;
  }

  const details = data?.fields?.file?.details as TAssetDetails;

  return {
    src: data?.fields?.file?.url || '',
    alt: data?.fields?.description || '',
    width: details?.image?.width || '',
    height: details?.image?.height || '',
    contentType: data?.fields?.file?.contentType || '',
  } as TImg;
}

export function flattenLink(data: TLinkEntry): TLink {
  const icon = data?.fields?.icon as TAssetEntry;
  const pageUrl = data?.fields?.page as TPageEntry;
  const href = addLeadingSlash(pageUrl?.fields?.slug || data?.fields?.url);
  const id = data?.fields?.id;

  return {
    label: data?.fields?.label,
    link: {
      href,
    },
    newTab: data?.fields?.newTab,
    icon: flattenAsset(icon),
    id,
  } as TLink;
}

export function flattenButton(
  data: TButtonEntry
): TButton | Record<string, never> {
  if (!data?.fields) return {};

  const icon = data?.fields?.icon as TAssetEntry;
  const page = data?.fields?.page as TPageEntry;
  const href = addLeadingSlash(page?.fields?.slug || data?.fields?.url);

  return {
    label: data?.fields?.label,
    link: {
      href,
    },
    newTab: data?.fields?.newTab,
    icon: flattenAsset(icon),
    variant: data?.fields?.variant,
  } as TButton;
}

export function flattenFile(data: TAssetEntry): TImg {
  return flattenAsset(data) || ({} as TImg);
}

export function flattenNavigation(data: TNavigationEntry): TNavigation {
  if (!data?.fields?.title && data?.fields?.link) {
    return data?.fields?.link?.map((item) => flattenLink(item as TLinkEntry));
  }

  if (data?.fields?.title) {
    return {
      title: data?.fields?.title
        ? flattenLink(data?.fields?.title as TLinkEntry)
        : '',
      links: Array.isArray(data?.fields?.link)
        ? data?.fields?.link?.map((item) => flattenLink(item as TLinkEntry))
        : [],
      break: data?.fields?.break,
    } as TMenu;
  }

  return {};
}

export function flattenTag(data: TTagEntry): TTag {
  return {
    id: data.sys?.id,
    tagName: data?.fields?.tagName,
  };
}

export function flattenPage(data: TPageEntry): TPageData {
  return transformPageData(data);
}

export function flattenFAQItem(data: TFAQItemEntry): TFAQItem {
  return {
    question: data?.fields?.question,
    answer: data?.fields?.answer,
  };
}

export function flattenIcon(data: TIconEntry): TIcon | Record<string, never> {
  if (!data?.fields?.iconName) return {};

  return { name: data?.fields?.iconName };
}

export function flattenListItem(data: TListItemEntry): TRichTextDocument {
  return data?.fields?.text;
}

export function flattenFeatureList(data: TFeatureListEntry) {
  if (!data?.fields) return {};
  const items = data?.fields?.items as TListItemEntry[];

  return {
    ...data?.fields,
    items: items.map(flattenListItem),
    CONTENT_TYPE: getContentType(data),
  };
}

export function flattenQuickActionCard(data: TQuickActionCardEntry) {
  if (!data?.fields) return {};

  const icon = data?.fields?.icon as TIconEntry;
  const link = data?.fields?.link as TLinkEntry;

  return {
    ...data?.fields,
    icon: flattenIcon(icon)?.name,
    link: flattenLink(link),
    CONTENT_TYPE: getContentType(data),
  };
}

export function flattenServiceCard(data: TServiceCardEntry) {
  if (!data?.fields) return {};

  const icon = data?.fields?.icon as TIconEntry;
  const featureList = data?.fields?.featureList as TFeatureListEntry;
  const button = data?.fields?.button as TButtonEntry;

  return {
    ...data?.fields,
    button: flattenButton(button),
    icon: flattenIcon(icon)?.name,
    featureList: flattenFeatureList(featureList),
    CONTENT_TYPE: getContentType(data),
  };
}

export function flattenBlockRichText(data: TBlockRichTextEntry) {
  if (!data?.fields) return {};

  return {
    ...data?.fields,
    CONTENT_TYPE: getContentType(data),
  };
}

export function flattenRelatedContentCard(data: TRelatedContentCardEntry) {
  if (!data?.fields) return {};

  const image = data?.fields?.image as TAssetEntry;
  const link = data?.fields?.link as TLinkEntry;

  return {
    ...data?.fields,
    image: flattenAsset(image) as TImg,
    link: flattenLink(link),
    CONTENT_TYPE: getContentType(data),
  };
}

export function flattenSequenceItem(
  data: TSequenceItemEntry
): TSequenceItem | Record<string, never> {
  if (!data?.fields) return {};

  const image = data?.fields?.image as TAssetEntry;

  return {
    ...data?.fields,
    title: data?.fields?.title ?? '',
    text: data?.fields?.text ?? '',
    image: flattenAsset(image) as TImg,
  };
}

export function flattenTabContentItem(
  data: TTabbedContentItemEntry
): TTabContentItem {
  const icon = data?.fields?.icon as TIconEntry;
  const content = data?.fields?.content as TEntry[];

  return {
    label: data?.fields?.label,
    content: transformContentBlocks(content),
    icon: flattenIcon(icon)?.name,
  };
}
