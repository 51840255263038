import { TImg } from '../transform-contentful-data/flatten-content-models/flatten-content-models.types';

export type TContentBlock = {
  type: TComponent;
  fields: any;
};

export enum EPageType {
  PAGE = 'page',
}

export type TPageData = {
  content: any[];
  pageType: EPageType;
  slug: string;
  title: string;
  thumbnail: TImg;
  createdAt: string;
  updatedAt: string;
  excerpt?: string;
  schema?: 'Article' | 'FAQ';
};

export type TComponent =
  | 'blockImagecontent'
  | 'blockRichText'
  | 'blockHeroBanner'
  | 'blockPrimaryHeroBanner'
  | 'blockIntro'
  | 'blockFaq'
  | 'blockMortgageCalculator'
  | 'blockMortgageComparisonForm'
  | 'blockCallToAction'
  | 'blockMortgageHeroBanner'
  | 'blockStampDutyCalculator'
  | 'blockCookieDeclaration'
  | 'blockSavingsCalculator'
  | 'blockSequence'
  | 'blockHousePriceIndex'
  | 'blockHousePricesOverTime'
  | 'blockEmbeddedComponent'
  | 'blockGrid'
  | 'blockDisclaimer'
  | 'blockCallback'
  | 'blockTabbedContent'
  | 'blockFeeChecker'
  | 'blockLeadCaptureForm'
  | 'blockReview'
  | 'relatedEntries'
  | 'relatedEntriesDynamic'
  | 'blockKeyStats'
  | 'blockContactUsForm'
  | 'blockComplaintsForm';

export type TComponentOptions = {
  padding?: boolean;
  paddingVariant?: 1;
};

export type TExtraProps = Record<string, any>;
export type TOptions = {
  sectionProps?: TComponentOptions;
  props?: TExtraProps;
};

export type TComponentMap = {
  [key in TComponent]?: [React.FC<any>, TOptions?];
};
