'use client';

import { ContactUsFormBlock } from '@pbx/shared/ui/blocks/contact-us-form-block';
import { useCreateContactUsRequest } from '@pbx/shared/api-client/services/communications';

import { TContactUsFormBlockContainerProps } from './contact-us-form-block-container.types';

export const ContactUsFormBlockContainer = ({
  title,
  description,
}: TContactUsFormBlockContainerProps) => {
  const { mutate, isSuccess, isPending } = useCreateContactUsRequest();

  return (
    <ContactUsFormBlock
      onFormSubmit={mutate}
      isSuccess={isSuccess}
      isPending={isPending}
      title={title}
      description={description}
    />
  );
};
