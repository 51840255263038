import { useQuery } from '@pbx/shared/api-client/hooks-core';
import { fetchApi } from '@pbx/shared/api-client/api-core';

import {
  TGetTrustPilotReviewsByAgentParams,
  TTrustPilotReviews,
  TUseGetTrustPilotReviewsByAgentParams,
} from '../reviews.types';
import { buildGetReviewsQuery } from '../helpers/query-builders';
import { GET_MARKETING_TRUST_PILOT_REVIEWS_BY_AGENT } from '../reviews.constants';

export async function getTrustPilotReviewsByAgent({
  agentId,
  stars = [5, 4],
  pageSize = 3,
  nextPageToken,
}: TGetTrustPilotReviewsByAgentParams) {
  const queryParams = buildGetReviewsQuery(stars, pageSize, nextPageToken);

  return await fetchApi<TTrustPilotReviews>(
    `${process.env.NEXT_PUBLIC_MARKETING_REVIEWS_API_URL}/Reviews/expert/${agentId}/trustpilot?${queryParams}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetTrustPilotReviewsByAgent({
  agentId,
  stars,
  pageSize,
  config,
}: TUseGetTrustPilotReviewsByAgentParams) {
  return useQuery({
    queryKey: [
      GET_MARKETING_TRUST_PILOT_REVIEWS_BY_AGENT,
      agentId,
      stars,
      pageSize,
    ],
    queryFn: () => getTrustPilotReviewsByAgent({ agentId, stars, pageSize }),
    ...config,
  });
}
