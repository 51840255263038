export function openRingCentralChat() {
  const ringCentralId = process.env.NEXT_PUBLIC_PB_RING_CENTRAL_ID;

  if (!ringCentralId) {
    return;
  }

  const button = document.querySelector(
    'button[data-selector="GUIDE_CHANNEL_BUTTON"]'
  );

  if (button) {
    (button as HTMLElement).click();
  }
}
