export function formatAsLocaleString(value?: string | number) {
  if (!value) {
    return '0';
  }

  let parsedValue = value;

  if (typeof value === 'string') {
    parsedValue = value.replace(/[\s,]/g, '');
  }

  parsedValue = Number(parsedValue);

  if (isNaN(parsedValue)) {
    parsedValue = '0';
  }

  return parsedValue.toLocaleString('en-GB', {
    minimumFractionDigits: 0,
  });
}
