import { fetchApi } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import {
  TGetTrustPilotReviewsByBusinessUnit,
  TTrustPilotReviews,
  TUseGetTrustPilotReviewsByBusinessUnit,
} from '../reviews.types';
import { GET_MARKETING_TRUST_PILOT_REVIEWS_BY_BUSINESS_UNIT } from '../reviews.constants';

export async function getTrustPilotReviewsByBusinessUnit({
  businessUnitId,
}: TGetTrustPilotReviewsByBusinessUnit) {
  return await fetchApi<TTrustPilotReviews>(
    `${process.env.NEXT_PUBLIC_MARKETING_REVIEWS_API_URL}/business-units/${businessUnitId}/reviews`,
    {
      mode: 'cors',
    }
  );
}

export function useGetTrustPilotReviewsByBusinessUnit({
  businessUnitId,
  config,
}: TUseGetTrustPilotReviewsByBusinessUnit) {
  return useQuery({
    queryKey: [
      GET_MARKETING_TRUST_PILOT_REVIEWS_BY_BUSINESS_UNIT,
      businessUnitId,
    ],
    queryFn: () => getTrustPilotReviewsByBusinessUnit({ businessUnitId }),
    ...config,
  });
}
