'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import ReCAPTCHA from 'react-google-recaptcha';
import { FocusEvent, useEffect, useRef, useState } from 'react';
import { AddressFinder } from '@ideal-postcodes/address-finder';

import { useAnalytics } from '@pbx/shared/analytics';
import { Form, FormField } from '@pbx/shared/ui/common/form/form';
import { Button } from '@pbx/shared/ui/common/button';
import {
  emailValidation,
  phoneNumberValidation,
  firstNameValidation,
  lastNameValidation,
  textareaValidation,
  postcodeValidation,
} from '@pbx/shared/utilities/validation/zod';
import { Textarea } from '@pbx/shared/ui/common/form/textarea';
import { Select } from '@pbx/shared/ui/common/form/select';
import { ToggleSelect } from '@pbx/shared/ui/common/form/toggle-select';
import { Input } from '@pbx/shared/ui/common/form/input';
import { Text } from '@pbx/shared/ui/common/text';

import { PersonalDetails } from './personal-details/personal-details';
import {
  EComplaintsFormType,
  EReason,
  ExtendedAddress,
  TComplaintsForm,
} from './complaints-form.types';
import { Address } from './address/address';

const formSchema = z.object({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  subject: z.string().optional(),
  phoneNumber: phoneNumberValidation,
  emailAddress: emailValidation,
  reason: z.nativeEnum(EReason, {
    message: 'Please select an option!',
  }),
  comments: textareaValidation,
  salesOrLettings: z.nativeEnum(EComplaintsFormType, {
    message: 'Please select an option!',
  }),
  propertyNumberOrName: z.string().min(1),
  street: z.string().min(1),
  city: z.string().min(1),
  postcode: postcodeValidation,
});

export const ComplaintsForm = ({
  onFormSubmit,
  isPending,
}: TComplaintsForm) => {
  const [reCaptchaSubmitted, setReCaptchaSubmitted] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
  });

  const shouldRender = useRef(true);

  useEffect(() => {
    if (!shouldRender.current) return;
    shouldRender.current = false;

    AddressFinder.setup({
      inputField: '#searchField',
      apiKey: process.env.NEXT_PUBLIC_IDEAL_POSTCODES || '',
      liStyle: {
        padding: '12px 16px',
      },
      defaultCountry: 'GBR',
      restrictCountries: ['GBR'],
      onAddressRetrieved: (address) => {
        const extendedAddress = address as ExtendedAddress;
        form.setValue('postcode', extendedAddress.postcode, {
          shouldValidate: true,
        });
        form.setValue('city', extendedAddress.post_town, {
          shouldValidate: true,
        });
        form.setValue('street', extendedAddress.thoroughfare, {
          shouldValidate: true,
        });
        form.setValue('propertyNumberOrName', extendedAddress.building_number, {
          shouldValidate: true,
        });
      },
    });
  }, [form]);

  const {
    trackGAFormEvent: { trackInputChange, trackSubmitError, trackSubmit },
  } = useAnalytics();

  const {
    formState: { isValid },
  } = form;

  function onBlur(e: FocusEvent<HTMLFormElement>) {
    trackInputChange(e.target);
  }

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (!isValid || !reCaptchaSubmitted) return;

    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      emailAddress: values.emailAddress,
      subject: values.subject,
      reason: values.reason,
      comments: values.comments,
      salesOrLettings: values.salesOrLettings,
      address: {
        propertyNumberOrName: values.propertyNumberOrName,
        street: values.street,
        city: values.city,
        postcode: values.postcode,
      },
    };

    onFormSubmit(formData, {
      onSuccess: () => {
        trackSubmit(formData);
      },
      onError: (error) => trackSubmitError(error),
    });
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onBlur={onBlur}
        className="bg-background-tertiary lg:p-13 space-y-11"
      >
        <PersonalDetails />
        <FormField control={form.control} name="salesOrLettings">
          <ToggleSelect
            columns={[2, 2, 2]}
            label="What's the complaint about?"
            type="single"
            className="w-full"
            options={[
              { value: EComplaintsFormType.SALES, label: 'Sales' },
              { value: EComplaintsFormType.LETTINGS, label: 'Lettings' },
            ]}
          />
        </FormField>
        <FormField control={form.control} name="reason">
          <Select
            label="Reason for complaint"
            options={[
              {
                value: EReason.PROPERTY_ADVERT,
                label: 'Property advert',
              },
              {
                value: EReason.PURPLEBRICKS_EMPLOYEE,
                label: 'Purplebricks employee',
              },
              {
                value: EReason.AGENTS_AVAILABILITY,
                label: 'Agent availability',
              },
              {
                value: EReason.CONVEYANCING_SERVICE,
                label: 'Conveyancing service',
              },
              {
                value: EReason.PAYMENT_TERMS,
                label: 'Payment terms',
              },
              {
                value: EReason.PROPERTY_OFFER,
                label: 'Property offer',
              },
              {
                value: EReason.PROPERTY_VIEWING,
                label: 'Property viewing',
              },
              {
                value: EReason.MONEY_BACK_GUARANTEE,
                label: 'Money back guarantee',
              },
            ]}
            className="w-full"
            placeholder="Select an option"
          />
        </FormField>
        <div>
          <Input
            label="Property address"
            placeholder="Start typing an address to autofill"
            id="searchField"
            type="text"
            className="w-full"
          />
          <div className="my-4 flex items-center">
            <div className="text-foreground-secondary flex-grow border-t"></div>
            <Text className="text-foreground-secondary px-4">
              OR ENTER MANUALLY
            </Text>
            <div className="text-foreground-secondary flex-grow border-t"></div>
          </div>
          <Address />
        </div>

        <FormField control={form.control} name="comments">
          <Textarea
            label="Please describe the situation you'd like us to look into"
            className="w-full"
          />
        </FormField>
        <ReCAPTCHA
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY as string}
          onChange={() => {
            setReCaptchaSubmitted(true);
          }}
          onExpired={() => {
            setReCaptchaSubmitted(false);
          }}
          onErrored={() => {
            setReCaptchaSubmitted(false);
          }}
          className="flex justify-end"
        />
        <div className="flex justify-end">
          <Button
            disabled={!isValid || !reCaptchaSubmitted || isPending}
            type="submit"
          >
            Submit complaint
          </Button>
        </div>
      </form>
    </Form>
  );
};
