'use client';

import { useFormContext } from 'react-hook-form';

import { FormField } from '@pbx/shared/ui/common/form/form';
import { Input } from '@pbx/shared/ui/common/form/input';

export function Address() {
  const { control } = useFormContext();

  return (
    <div className="space-y-4">
      <div className="flex items-start gap-4 sm:flex-col md:flex-row lg:flex-row">
        <FormField
          control={control}
          name="propertyNumberOrName"
          className="w-full"
        >
          <Input label="Property number / name" className="w-full" />
        </FormField>
        <FormField control={control} name="street" className="w-full">
          <Input label="Street" className="w-full" />
        </FormField>
      </div>

      <div className="flex items-start gap-4 sm:flex-col md:flex-row lg:flex-row">
        <FormField control={control} name="city" className="w-full">
          <Input label="City" className="w-full" />
        </FormField>
        <FormField control={control} name="postcode" className="w-full">
          <Input label="Postcode" className="w-full" />
        </FormField>
      </div>
    </div>
  );
}
