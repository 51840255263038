import Script from 'next/script';

export function OptimizelyScript({ optimizelyId }: { optimizelyId?: string }) {
  if (!optimizelyId) {
    return null;
  }

  return (
    <>
      <link
        rel="preload"
        href={`//cdn.optimizely.com/js/${optimizelyId}.js`}
        as="script"
      />
      <link rel="preconnect" href="//logx.optimizely.com" />
      <Script
        src={`//cdn.optimizely.com/js/${optimizelyId}.js`}
        data-cookieconsent="statistics"
        type="text/plain"
        id="optimizelyManagedScriptTag"
        data-testid="optimizelyManagedScriptTag"
      />
    </>
  );
}
