import { GoogleTagManager } from './google-tag-manager/google-tag-manager';
import { CookieManager } from './cookie-manager/cookie-manager';
import { OptimizelyScript } from './optimizely/optimizely-script';
import { RingCentralLiveChatScript } from './ring-central-live-chat/ring-central-live-chat-scripts';

export function ThirdPartyScripts() {
  const tagManagerId = process.env.PB_GTM_API_KEY ?? '';
  const cookiebotId = process.env.PB_COOKIEBOT_ID ?? '';
  const optimizelyId = process.env.NEXT_PUBLIC_OPTIMIZELY_ID ?? '';
  const ringCentralId = process.env.NEXT_PUBLIC_PB_RING_CENTRAL_ID ?? '';

  return (
    <>
      <GoogleTagManager tagManagerId={tagManagerId} />
      <CookieManager cookieManagerId={cookiebotId} />
      <OptimizelyScript optimizelyId={optimizelyId} />
      <RingCentralLiveChatScript ringCentralId={ringCentralId} />
    </>
  );
}
