import { TFetchConfig } from '../api-core.types';

export async function requestInterceptor(
  config: TFetchConfig
): Promise<TFetchConfig> {
  // Ensure headers object exists
  config.headers = config.headers || {};

  if (process.env.NEXT_PUBLIC_APP_NAME) {
    config.headers['X-App-Name'] = process.env.NEXT_PUBLIC_APP_NAME;
  }

  if (process.env.NEXT_PUBLIC_VERCEL_DEPLOYMENT_ID) {
    config.headers['X-App-Version'] =
      process.env.NEXT_PUBLIC_VERCEL_DEPLOYMENT_ID;
  }

  if (config?.body) {
    config.body = JSON.stringify(config.body);
  }

  // Access and log custom properties if needed example:
  // if (config?.platform) {
  //   console.info(`Platform: ${config.platform}`);
  // }

  return config;
}

// Request Interceptor Error: Handle errors that occur during the request configuration phase
export function requestInterceptorError(error: unknown): Promise<Error> {
  return Promise.reject(error);
}

// Response Interceptor: Modify or handle the response before it’s returned
export async function responseInterceptor(
  response: Response
): Promise<Response> {
  // Custom logic for handling the response can be added here

  return response;
}

// Response Interceptor Error: Handle errors that occur during the response phase
export function responseInterceptorError(error: Error): Promise<Error> {
  return Promise.reject(error);
}
