'use client';
import { useEffect } from 'react';

import { openRingCentralChat } from './ring-central-live-chat';

export const useOpenLiveChatOnButtonClick = (id = 'open-ring-central-chat') => {
  useEffect(() => {
    const button = document.getElementById(id);
    if (button) {
      const handleChatButtonClick = (event: MouseEvent) => {
        event.preventDefault();
        openRingCentralChat();
      };
      button.addEventListener('click', handleChatButtonClick);

      return () => {
        button.removeEventListener('click', handleChatButtonClick);
      };
    }
  }, [id]);
};
