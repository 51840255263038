import Script from 'next/script';

export function RingCentralLiveChatScript({
  ringCentralId,
}: {
  ringCentralId?: string;
}) {
  if (!ringCentralId) {
    return null;
  }

  return (
    <Script id="optimizelyManagedScriptTag" strategy="afterInteractive">
      {`(function(n,u){
            window.CXoneDfo=n,
            window[n]=window[n]||function(){
                (window[n].q=window[n].q||[]).push(arguments)
            },
            window[n].u=u,
            e=document.createElement("script"),
            e.type="module",
            e.src=u+"?"+Math.round(Date.now()/1e3/3600),
            document.head.appendChild(e)
          })('cxone','https://web-modules-de-uk1.niceincontact.com/loader/1/loader.js');
          cxone('init', '1345');
          cxone('guide','init', '${ringCentralId}');
          cxone('guide', 'setButtonSize', '60px');
        `}
    </Script>
  );
}
