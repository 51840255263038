export const screenTokens: Record<string, any> = {
  sm: { min: '0px', max: '599px' },
  // => @media (min-width: 320px and max-width: 599px) { ... }

  md: { min: '600px', max: '1200px' },
  // => @media (min-width: 600px and max-width: 1200px) { ... }

  lg: { min: '1201px' },
  // => @media (min-width: 1201px) { ... }
};
