import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { TLandRegistryHousePricesResponse } from '../location.types';
import { GET_LAND_REGISTRY_SOLD_HOUSE_PRICES } from '../location.constants';
import { formatLocationPostTown } from './format-location-post-town/format-location-post-town';

export async function getLandRegistrySoldHousePricesByLocation(
  postTown: string,
  pageSize = '25'
) {
  return await fetchApi<TLandRegistryHousePricesResponse>(
    `https://landregistry.data.gov.uk/data/ppi/transaction-record.json?_pageSize=${pageSize}&_page=0&propertyAddress.town=${formatLocationPostTown(postTown)}&_sort=-transactionDate`
  );
}

export function useGetLandRegistrySoldHousePricesByArea(
  postTown: string,
  config: TUseQueryOptions<TLandRegistryHousePricesResponse> = {}
) {
  return useQuery({
    queryKey: [GET_LAND_REGISTRY_SOLD_HOUSE_PRICES, postTown],
    queryFn: () => getLandRegistrySoldHousePricesByLocation(postTown),
    ...config,
  });
}
