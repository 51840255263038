import * as React from 'react';

import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Label } from '@pbx/shared/ui/common/form/label';
import { Error } from '@pbx/shared/ui/common/form/error';

import { TTextareaProps } from './textarea.types';
import { variants } from './textarea.variants';

const Textarea = React.forwardRef<HTMLTextAreaElement, TTextareaProps>(
  ({ className, label, helper, error, disabled, rows = 4, ...props }, ref) => {
    const getVariant = () => {
      if (disabled) return 'disabled';

      if (error) return 'error';

      return undefined;
    };

    return (
      <div className={cn('inline-flex flex-col gap-3', className)}>
        <Label helper={helper}>{label}</Label>
        <textarea
          className={cn(variants({ variant: getVariant() }))}
          ref={ref}
          rows={rows}
          disabled={disabled}
          {...props}
        />
        {error && <Error>{error}</Error>}
      </div>
    );
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };
