export const addLeadingSlash = (url?: string) => {
  if (!url) return '';

  if (
    url.startsWith('/') ||
    url.startsWith('http') ||
    url.includes('mailto:') ||
    url.includes('tel:')
  ) {
    return url;
  }

  return `/${url}`;
};
