import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { TExpertProfile } from '../agents.types';
import { GET_EXPERT_PROFILE_BY_USER_ID } from '../agents.constants';

export async function getExpertProfileByUserId(userId: string) {
  return await fetchApi<TExpertProfile>(
    `${process.env.NEXT_PUBLIC_EXPERT_TEAM_API_URL}/api/ExpertProfile/FindByUserId/${userId}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetExpertProfileByUserId(
  userId: string,
  config: TUseQueryOptions<TExpertProfile> = {}
) {
  return useQuery({
    queryKey: [GET_EXPERT_PROFILE_BY_USER_ID, userId],
    queryFn: () => getExpertProfileByUserId(userId),
    ...config,
  });
}

export function useGetExpertProfileByUserIds(
  userIds: string[],
  config: TUseQueryOptions<TExpertProfile[]> = {}
) {
  return useQuery({
    queryKey: [GET_EXPERT_PROFILE_BY_USER_ID, userIds],
    queryFn: () =>
      Promise.all(userIds.map((userId) => getExpertProfileByUserId(userId))),
    ...config,
  });
}
