import { useFormContext } from 'react-hook-form';
import { FormEvent, useCallback } from 'react';

import { FormField } from '@pbx/shared/ui/common/form/form';
import { RadioGroup } from '@pbx/shared/ui/common/form/radio-group';
import { Input } from '@pbx/shared/ui/common/form/input';
import { ToggleSelect } from '@pbx/shared/ui/common/form/toggle-select';
import { EFieldName } from '@pbx/shared/api-client/services/mortgages';
import { convertLocalStringValueToNumber } from '@pbx/shared/utilities/convert-local-string-value-to-number';
import { formatAsLocaleString } from '@pbx/shared/utilities/format-as-locale-string';
import { LTVChart } from '@pbx/shared/ui/common/ltv-chart';

import { TUseFormReturn } from '../lead-capture-form.types';
import {
  ANY_CCJS_TEST_ID,
  EXTRA_SUPPORT_TEST_ID,
  UK_CITIZEN_TEST_ID,
  WHERE_ARE_YOU_AT_OPTIONS,
  YES_NO_OPTIONS,
} from '../lead-capture-form.constants';
import { TPurchaseJourneyProps } from './purchase-journey.types';

export function PurchaseJourney({
  showSecondStep,
  showThirdStep,
  showFourthStep,
  showFifthStep,
  showSixthStep,
  propertyValue,
  deposit,
}: TPurchaseJourneyProps) {
  const { control } = useFormContext<TUseFormReturn>();
  const parseAmount = useCallback(
    (e: FormEvent<HTMLElement>) =>
      convertLocalStringValueToNumber((e.target as HTMLInputElement).value),
    []
  );
  const formatAmount = useCallback((amount?: number) => {
    return amount ? formatAsLocaleString(String(amount)) : undefined;
  }, []);

  return (
    <>
      <FormField control={control} name={EFieldName.WHERE_ARE_YOU_AT}>
        <RadioGroup
          label="Where are you at in your house buying journey?"
          options={WHERE_ARE_YOU_AT_OPTIONS}
          data-group-label="Where are you at in your house buying journey?"
        />
      </FormField>
      {showSecondStep && (
        <div className="flex sm:flex-col md:flex-row lg:flex-row">
          <div className="space-y-8 sm:mb-8 sm:w-full md:w-full lg:w-1/2">
            <FormField
              control={control}
              name={EFieldName.ANNUAL_INCOME}
              format={formatAmount}
              parse={parseAmount}
            >
              <Input
                label="Annual income of all applicants"
                className="w-full"
                icon="£"
              />
            </FormField>
            {showThirdStep && (
              <>
                <FormField
                  control={control}
                  name={EFieldName.PROPERTY_VALUE}
                  format={formatAmount}
                  parse={parseAmount}
                >
                  <Input label="Property value" className="w-full" icon="£" />
                </FormField>
                <FormField
                  control={control}
                  name={EFieldName.DEPOSIT}
                  format={formatAmount}
                  parse={parseAmount}
                >
                  <Input label="Deposit" className="w-full" icon="£" />
                </FormField>
              </>
            )}
          </div>
          <div className="flex flex-col items-center justify-center sm:w-full md:w-full lg:w-1/2">
            <LTVChart propertyValue={propertyValue} deposit={deposit} />
          </div>
        </div>
      )}
      {showFourthStep && (
        <FormField control={control} name={EFieldName.UK_CITIZEN}>
          <ToggleSelect
            columns={[2, 2, 2]}
            label="Are you a UK citizen or have been living in the UK for more than 12 months?"
            type="single"
            className="w-full"
            options={YES_NO_OPTIONS}
            testId={UK_CITIZEN_TEST_ID}
          />
        </FormField>
      )}
      {showFifthStep && (
        <FormField control={control} name={EFieldName.ANY_CCJS}>
          <ToggleSelect
            columns={[2, 2, 2]}
            label="Have you had any CCJs, defaults, bankruptcies, or missed payments within the last year?"
            type="single"
            className="w-full"
            options={YES_NO_OPTIONS}
            testId={ANY_CCJS_TEST_ID}
          />
        </FormField>
      )}
      {showSixthStep && (
        <FormField control={control} name={EFieldName.EXTRA_SUPPORT}>
          <ToggleSelect
            columns={[2, 2, 2]}
            label="Do you feel you may need extra support or adjustments in how we handle your case?"
            type="single"
            className="w-full"
            options={YES_NO_OPTIONS}
            testId={EXTRA_SUPPORT_TEST_ID}
          />
        </FormField>
      )}
    </>
  );
}
