import { cva } from 'class-variance-authority';

export const navigationLinkVariants = cva(
  'relative inline-flex items-center justify-center hover:underline-none focus-visible:border-focus focus:outline-none ',
  {
    variants: {
      variant: {
        primary:
          'rounded-large sm:rounded-small md:rounder-small sm:justify-items-center shrink-0 flex bg-brand-background-primary text-brand-foreground-primary lg:gap-1 p-3 lg:hover:bg-brand-background-secondary sm:flex-col sm:text-brand-foreground-primary sm:pl-2 sm:hover:text-brand-foreground-highlight sm:shrink-0 md:flex-col md:text-brand-foreground-primary md:pl-2 md:hover:text-brand-foreground-highlight md:shrink-0  md:hover:bg-transparent sm:hover:bg-transparent',
        'main-menu':
          'gap-1 w-90px h-48px py-3 px-2 g-1 rounded-small text-brand-foreground-primary bg-brand-background-primary hover:bg-brand-background-secondary',
        secondary:
          'hover:text-background-action hover:bg-background-tertiary sm:text-foreground-action-alt md:text-foreground-action-alt gap-4 px-4',
        'main-menu-secondary':
          'hover:text-foreground-action-alt gap-4 px-4 py-3 hover:bg-background-tertiary',
      },
    },
  }
);

export const iconVariants = cva('', {
  variants: {
    variant: {
      primary: '',
      'main-menu':
        'text-brand-foreground-action p-1 group-data-[state=open]:-rotate-180 transition-transform duration-250',
      secondary:
        'hover:text-background-action hover:underline group-data-[state=open]:-rotate-180',
      'main-menu-secondary':
        'text-foreground-action-alt group-data-[state=open]:-rotate-180 transition-transform duration-250',
    },
  },
});
